.product-teaser {
    position: relative;
    overflow: hidden;
}

.event-teaser {
    position: relative;
    display: flex;
    margin-bottom: 30px;
    padding: 30px 25px;
    background-color: #f6f6f6;
    border-radius: 10px;
}

.event-teaser .event-image .field-field-image {
    border-radius: 70%;
    position: relative;
    overflow: hidden;
}

.event-image-webinar {
    width: 100px;
    height: 100px;
    vertical-align: middle;
}
.text-webinar{
    text-align: center;
    margin-top: 10px;
}

.event-teaser .event-content-wrap {
    position: relative;
    width: 75%;
    margin-left: 50px;
}

.event-teaser .event-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    padding-bottom: 15px;
    margin-bottom: 15px;
    padding-right: 100px;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

a, a:hover, a:focus, a:visited {
    text-decoration: none;
    outline: none;
}

a {
    color: #333333;
}

.event-teaser .event-price {
    position: absolute;
    right: 0;
    top: 0;
    color: #0f52ba;
    font-size: 24px;
    font-weight: 500;
}

.event-teaser .event-meta {
    display: flex;
}

.event-teaser .event-date-wrap {
    width: 30%;
    flex: 1;
}

.event-teaser .event-time {
    width: 25%;
    flex: 1;
}

.event-teaser .event-venue-wrap {
    flex: 2;
}

.event-teaser .event-date span {
    display: block;
    line-height: 1.2;
}

.event-teaser .event-date-day {
    font-size: 25px;
    font-weight: 300;
    float: left;
    line-height: 1;
    margin-right: 7px;
    color: #0f52ba;
}

.event-teaser .event-date-month {
    text-transform: uppercase;
}

.event-teaser .event-venue-wrap, .event-teaser .event-date-wrap, .event-teaser .event-time {
    width: 100%;
}


.event-teaser .event-time, .event-teaser .event-venue-wrap {
    padding-top: 5px;
}

.event-teaser .event-venue, .event-teaser .event-location {
    display: inline-block;
}

.event-teaser .event-button {
    position: relative;
}

.button {
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.1;
    margin: 10px 0;
    outline: 0 none;
    padding: 10px 20px;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    position: relative;
    white-space: nowrap;
    transition: 0.3s;
    background-color: #0f52ba;
    overflow: hidden;
    margin-top: 53px;
    margin-left: 20px;
}
.info_box  p {
    font-size: 18px !important;
    line-height: 20px;
    padding-top: 16px;
    font-weight: 300 !important;
}
