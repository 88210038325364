.announcementIcon {
  margin-right: 5px;
  height: 29px;
  width: 29px;
  margin-bottom: 15px;
  cursor: pointer;
}

.badge {

}

@primary-color: #36c889;