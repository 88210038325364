/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.popover {
  width: 336px;
}
.noticeButton {
  cursor: pointer;
  display: inline-block;
  transition: all 0.3s;
}
.icon {
  padding: 4px;
}
.tabs :global .ant-tabs-nav-scroll {
  text-align: center;
}
.tabs :global .ant-tabs-bar {
  margin-bottom: 4px;
}
:global .privateMessageBox .ant-card-body {
  padding: 0;
}
:global .privateMessageBox .ant-card-head {
  background: white;
  min-height: auto;
  color: #0700d9;
  border-bottom: 2px solid #53a0e4;
}
:global .antd-pro-components-notice-icon-index-popover {
  width: 450px;
}
