.test {
    display: block ;
  }

.qualItem {
    display: flex;
  margin-left: 20px;
  }
  input {
    width:  120px;
  }
  .inputNumber {
    // width: 120px;
    margin-left: 5px;
  }
  .minWidth {
    min-width: 200px;
    margin-left: 5px;

  }

@primary-color: #36c889;