:global {
  .header1 {
    font-size: 20px;
    text-decoration: underline;
  }

  .mt20 {
    margin-top: 20px !important;
  }

  .aggreementCard {
    padding: 20px 80px 20px 50px !important;
    background-color: white;
    font-size: 15px;
    line-height: 1.7;

    a {
      text-decoration: underline !important;
    }

    p {
      margin-bottom: 20px !important;
      text-align: justify;
    }

    ol, ul {
      margin-left: 10px !important;

      li {
        padding-left: 10px !important;
      }
    }

    ol, ul {
      list-style: auto !important;
    }

    ol {
      > li {
        padding-top: 20px;

        &::marker {
          font-weight: bold !important;
        }
      }
    }

    ul {
      padding-left: 10px;

      &.alpha {
        list-style: lower-alpha !important;
      }

      &.roman {
        list-style: lower-roman !important;

        li::marker {
          content: "(" counter(list-item) ")";
          list-style-type: circle !important;
        }
      }
    }


  }

  .themeColor {
    color: #0872BC;
    font-weight: bold !important;
  }
}
@primary-color: #36c889;