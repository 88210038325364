
.userNameBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;

  div {
    margin-top: 5px;
  }
}

@primary-color: #36c889;