
.userNameBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;

  div {
    margin-top: 5px;
  }
}

.statusBox {
  display: flex;
  align-items: center;

}

.unReadIcon {
  height: 12px;
  width: 12px;
  background: #f0ad4e;
  border-radius: 50%;
}

.nameLabel {
  margin-left: 0;
}

@primary-color: #36c889;