/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.main {
  max-width: 450px;
  margin: 0 auto;
}
@media screen and (max-width: 576px) {
  .main {
    width: 95%;
  }
}
.main .icon {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.2);
  margin-left: 16px;
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.3s;
}
.main .icon:hover {
  color: #36c889;
}
.main .other {
  text-align: left;
  margin-top: 24px;
  line-height: 22px;
}
.main .other .register {
  float: right;
}
.main .forgot {
  float: right;
}
.main .button {
  width: 100%;
}
.main .otpInfo {
  font-size: 16px;
  line-height: 1.5em;
  text-align: center;
  margin-bottom: 20px;
}
.main .antCodeBox {
  font-size: 15px;
  line-height: 1.5em;
  text-align: center;
  margin-bottom: 20px;
}
.webInfo {
  font-size: 22px;
  color: black;
  line-height: 1.5em;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}
.webInfo small {
  font-size: 13px;
  color: grey;
}
