.agent_form {
    background-color: white;
}

.agent_form .right_bg {
    height: auto;
    background-position: 35%;
}
.img-right{
    width: 100%;
    height: auto;
     vertical-align: middle;
}

.agent_form .form-container {
    padding: 2vw 4vw 2vw 4vw;
}

.align-center {
    text-align: center;
}

.agent-name {
    font-size: 37px;
    text-transform: none;
    line-height: 50px;
    margin-top: 10px;
    margin-bottom: 25px;
}

.form-control {
    height: 50px;
    border-radius: 0;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.section-card {
    padding: 40px 0;
    overflow: hidden;
    border: 1px solid lightgray;
    border-radius: 5px;
    
}

.info_box h3 {
    font-size: 20px;
    font-weight: 400 !important;
    line-height: 1.9;
    color: #52565e;
}