
.userNameBox {
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;
  //width: 120px;
  div {
    margin-top: 5px;
  }
}

.smallAvat {
  background: white;
  vertical-align: middle;
  margin-right: 7px;
  border-radius: 50%;
  color: black;
  height: 17px;
  width: 17px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-left: 5px;
}

@primary-color: #36c889;