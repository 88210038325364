/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.head {
  width: 100%;
  transition: background 0.3s, width 0.2s;
  height: 64px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
}
.head :global .ant-menu-submenu.ant-menu-submenu-horizontal {
  line-height: 64px;
  height: 100%;
}
.head :global .ant-menu-submenu.ant-menu-submenu-horizontal .ant-menu-submenu-title {
  height: 100%;
}
.head.light {
  background-color: #fff;
}
.head .main {
  display: flex;
  height: 64px;
  padding-left: 24px;
}
.head .main.wide {
  max-width: 1200px;
  margin: auto;
  padding-left: 0;
}
.head .main .left {
  flex: 1;
  display: flex;
}
.head .main .right {
  width: 324px;
}
.logo {
  width: 165px;
  height: 64px;
  position: relative;
  line-height: 64px;
  transition: all 0.3s;
  overflow: hidden;
}
.logo img {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
}
.logo h1 {
  color: #fff;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  margin: 0 0 0 12px;
  font-weight: 400;
}
.light h1 {
  color: #0872BC;
}
.menu {
  border: none;
  height: 64px;
  line-height: 64px;
}
