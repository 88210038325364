@primary: #1890ff;
@success: #0872BC;
@successGreen: #5cb85c;
@mainColor: #0f52ba;

:global {
  .chatBox {
    .ant-card-body {
      padding: 0;
    }


    .emptyBox {
      height: 400px;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .list {
      padding: 0;
      border-right: 1px solid #ddd;
      height: 100vh;


      ul {
        //margin-top: 20px;
        border-top: 1px solid #ddd;

        li {
          border-bottom: 1px solid #ddd;
          padding: 10px;
        }
      }
    }

    .chatScreen {
      display: flex;
      //border-right: 1px solid #ccc;
      //border-left: 1px solid #ccc;
      height: 72vh; // this is height for chat modal
      padding-bottom: 10px;

      textarea {
        padding: 15px 20px 12px !important;
        border-radius: 50px !important;
        border: 1px solid #ddd !important;
        margin: 0 5px 0 10px !important;
        background-color: #f0f0f0;
        border-bottom: 2px solid #c4c4c4 !important;
        height: 45px !important;
        line-height: 1 !important;
        overflow: -moz-scrollbars-none !important;
        -ms-overflow-style: none !important;
        overflow: hidden !important;

        &::-webkit-scrollbar {
          width: 0 !important;
        }

        &::placeholder {
          color: #b9bbbe;
        }
      }


      div {
        border-top: none !important;
      }


    }
  }

  .chat-float-btn {
    position: fixed;
    z-index: 99;
    bottom: 6%;
    right: 2%;
    //background: white;
    //padding:10px;
    //border-radius: 50%;
    //box-shadow: 0 0 20px #ddd;

    img {
      height: 56px;
    }

    .chatCount {
      position: absolute;
      right: -5px;
      //background: #0872BC;
      //background: #f0ad4e;
      background: #d9534f;
      padding: 0;
      border-radius: 50px;
      height: 22px;
      min-width: 35px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -10px;
      font-size: 12px;
      border: 3px solid white;
    }
  }

  .ant-list-items {
    margin-top: 10px;
  }

  .textDiv {
    display: flex;

    span {
      //padding-top: 5px;
      padding-left: 5px;
    }
  }


  .chat-room-list {
    ul {

      li {
        padding: 10px 10px 10px 20px;
        border-bottom: 1px solid #ececec;
        //border-bottom: none;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .infoDiv {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .innerInfo {
            flex-direction: column;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding-top: 5px;
            margin-right: 10px;
          }

          i {
            color: #d7d7d7;
          }
        }

        &:hover {
          background: #ececec;
          border-radius: 10px;
          cursor: pointer;
        }
      }
    }

    .ant-list-item-action {
      li {
        border-bottom: none;
        margin-bottom: 8px;

        a {
          background: #5cb85c;
          border-radius: 50%;
          width: 25px !important;
          height: 25px;
          display: flex;
          justify-content: center;
          color: white;
          padding: 5px;
          align-items: center;
          font-size: 12px;

          &:hover {
            background: #5cb85c;
            color: white;
          }
        }
      }
    }
  }


  .avatar {
    //border: 1px solid @success;
    height: 35px;
    width: 35px;
    //padding: 3px;
    margin-right: 10px;
    padding: 0 !important;
  }

  .goBackBtn {
    background: white;
    height: 35px;
    width: 35px;
    margin-right: 10px;
    padding: 0 !important;
    color: @mainColor;
    line-height: 1.5;
  }

  .headerBox {
    display: flex;
    flex-direction: column;

    .headerField {
      text-transform: capitalize;
      font-weight: bold;
      font-size: 12px;
    }

    .titleField {
      text-transform: capitalize;
      font-size: 11px;
    }
  }


  .unreadSpan {
    height: 25px;

    .unreadCount {
      background-color: @mainColor;
      color: white;
      padding: 0;
      border-radius: 50px;
      height: 20px;
      width: 20px;
      display: inline-block;
      text-align: center;
      font-size: 12px;
      line-height: 1.7;
    }
  }

  .time {
    color: @mainColor;
    font-size: 12px;
  }

  .chatDrawer {
    .ant-drawer-content {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .ant-drawer-header {
      background-color: @mainColor;

      .ant-drawer-title {
        color: white;
      }

      .ant-drawer-close {
        color: white;
        margin-top: 5px;
      }
    }
  }

  .sendBtnBox {
    padding: 0 10px 0 0;
    margin-bottom: 1px;

    img {
      height: 42px;
      position: relative;
      top: 0;
    }
  }

  .plusBtnBox {
    padding: 0 10px 0 0;
    cursor: pointer;

    img {
      height: 48px;
      position: relative;
      top: 3px;
    }
  }


  .chat-popup {
    display: block;
    position: fixed;
    bottom: 0;
    right: 15px;
    z-index: 99;
    height: 85vh; //  this is height for modal box
    background: white;
    top: 12%;
    box-shadow: 2px 2px 10px #ddd;
    border-radius: 10px;
    width: 400px;

    .input-group-box {
      float: none !important;

      .form-control {
        height: 35px !important;
      }
    }

    .conversationList {
      padding: 0;
      overflow-y: scroll !important;
      position: relative;
      height: 70vh;

      h3 {
        font-size: 17px;
        margin-top: 15px;
        margin-left: 15px;
        margin-bottom: 20px;
      }
    }

    .header {
      padding: 20px;
      background: #039;
      color: white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .topHeader {
        display: flex;
        justify-content: space-between;
      }

      .menuBtn {
        width: 100px;
        text-align: right;

        a {
          margin: 0 5px;
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (max-width: 560px) {
    .chat-popup {
      display: none;
    }

    .chat-float-btn {
      display: none;
    }
  }
}

@primary-color: #36c889;